@charset "utf-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Philosopher");
@import url("https://fonts.googleapis.com/css?family=Play:700");
@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans:300");
@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900");
@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");

/* @font-face {
    font-family: 'si';
    src: url('../pi/socicon.eot');
    src: url('../pi/socicon.eot?#iefix') format('embedded-opentype'),
         url('../pi/socicon.woff') format('woff'),
         url('../pi/socicon.ttf') format('truetype'),
         url('../pi/socicon.svg#icomoonregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family:si;
        src: url(../pi/socicon.svg) format(svg);
    }
} */

* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}

/*************************************** common style start ********************************************/
a {
  text-decoration: none;
}
.form-control,
.btn {
  border-radius: 0;
}
.text-green {
  color: #59a500;
}
.marright {
  margin-right: 8px;
}

.text-yellow {
  color: #f1b73b;
}
.text-lightGray {
  color: #d2d2d2;
}
.text-white {
  color: #fff !important;
}
.form-group {
  margin-bottom: 0;
}
.go_center {
  display: table;
  margin: 0 auto;
}
.height40 {
  height: 40px;
}
.width100 {
  width: 100%;
}
.martopbtn5 {
  margin-top: 5px;
}
.martopbtn10 {
  margin-top: 10px;
}
.martopbtn20 {
  margin-top: 20px;
}
.martop30xs10 {
  margin-top: 30px;
}
.martop145xs100 {
  margin-top: 145px;
}
.marbottom30 {
  margin-bottom: 30px;
}
.mtoplg20xs0 {
  margin-top: 20px;
}
.mtoplg40xs0 {
  margin-top: 80px;
}
.mtoplg30xs10 {
  margin-top: 30px;
}
.marleft30 {
  margin-left: 30px;
}
.marleft10 {
  margin-left: 10px;
}
.marleft10xs-3 {
  margin-left: 10px;
}
.padleftright0 {
  padding-left: 0;
  padding-right: 0;
}
.padleftright2 {
  padding-left: 2px;
  padding-right: 2px;
}
.padleftright5 {
  padding-left: 4px;
  padding-right: 4px;
}
.padright0 {
  padding-right: 0px;
}
.padright4 {
  padding-right: 4px;
}
.padleft0 {
  padding-left: 0;
}
.padleft4 {
  padding-left: 4px;
}
.marleftright {
  margin-right: 0px;
  margin-left: 0px;
}
ul {
  margin-bottom: 0;
}

.text-blue {
  color: #017fbe;
}

/*************************************** common style close ********************************************/

/*************************************** genarel style start ********************************************/
.topHeader {
  background: #f8f8f8;
  border-bottom: 1px solid #ccc;
}
.comLogo img {
  height: 54px;
  padding: 8px 0;
}
.userAc {
  background: #2c3135;
  padding: 12px 20px;
}
.userAc img {
  height: 30px;
  width: 30px;
  float: left;
  margin-right: 15px;
}
.userAc ul li {
  list-style: none;
  padding: 4px 0;
}
.userAc .dropdown a {
  color: #fff;
  text-decoration: none;
}
.userAc .dropdown .dropdown-menu,
.drpul {
  background: #fff;
}
.userAc .dropdown-menu > li > a:focus,
.userAc .dropdown-menu > li > a:hover {
  background-color: #2c3135;
  color: #fff;
}

.notiSec {
  padding: 8px 0;
}
.notiSec ul li {
  float: left;
  padding: 5px 15px;
  border-left: 1px solid #eee;
  list-style: none;
}
.notiSec ul li:first-child {
  border-left: none;
}
.notiSec ul {
  float: right;
}
.notiSec ul li a {
  color: #9ca0a2;
  font-size: 20px;
}
.editBd {
  background: red;
  position: absolute;
  right: 6px;
  top: 3px;
  color: #fff;
  padding: 1px 4px;
  font-size: 9px;
  border: 4px;
}
.notiC {
  position: relative;
}
.drpul {
  left: inherit;
  right: 0;
  padding: 0;
}
.drpul > li > a:focus,
.drpul > li > a:hover {
  background-color: #eee;
  color: #333;
}
.drpul li a {
  font-size: 13px !important;
}
.drpul li {
  padding: 0 !important;
  margin: 2px 0 !important;
  float: none !important;
}
/* .vIcon {
  font-size: ;
} */

.searchForm {
  padding: 10px 0;
}

.leftMenu {
  position: relative;
  background: #f8f8f8;
  width: 16%;
  height: 605px;
  float: left;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  border-right: 1px solid #ccc;
}
.middleSec {
  float: left;
  height: 605px;
  width: 84%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 10px;
}
.headingD {
  background: #fff;
  color: #333;
  font-size: 18px;
  padding: 6px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.leftMenuColapse {
  position: absolute;
  top: 40px;
  right: -20px;
  display: block;
  background: #eee;
  color: #333;
  font-size: 14px;
  padding: 4px 7px;
  padding-left: 16px;
  z-index: 999;
  border-radius: 18px;
  cursor: pointer;
}
.leftMenuColapseRight {
  position: absolute;
  top: 40px;
  right: -20px;
  display: block;
  background: #eee;
  color: #333;
  font-size: 14px;
  padding: 4px 7px;
  padding-left: 16px;
  z-index: 999;
  border-radius: 18px;
  cursor: pointer;
}

.leftMenu ul li {
  list-style: none;
}
.leftMenu ul li:hover a {
  background: #eee;
}
.leftMenu ul li:hover ul li a {
  background: transparent;
}
.leftMenu ul li ul li:hover a {
  background: #eee;
}
.leftMenu ul li a {
  color: #333;
  display: block;
  padding: 8px;
  text-decoration: none;
  border-bottom: 1px solid #eee;
}
.leftMenu ul li ul li a {
  padding: 5px 8px;
}

.leftMenu ul li .dropdown-menu {
  position: inherit;
  float: none;
  padding: 0;
  padding-left: 20px;
  margin: 0;
  font-size: 13px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  min-width: auto;
}

.leftMenuSec ul li a .menuarrowActive {
  font-size: 23px;
  margin-right: -11px;
  color: #fff;
  display: none;
}

.leftProfileSec {
  padding: 20px 10px;
  background: #eee;
  text-align: center;
}
.leftProfileSec p {
  color: #000;
  margin-bottom: 0;
  margin-top: 10px;
}
.leftProfileSec img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.leftMenuSec {
  padding: 20px 0;
}
.leftMenuSec > ul {
  padding-left: 0px;
}

.leftMenuDropdown {
  padding-left: 25px;
}
.leftMenuDropdown ul li:hover a {
  background: transparent;
}
.leftMenuDropdown ul li a {
  font-size: 12px;
  padding: 4px 0;
  border: none !important;
}

.eventMainD {
  position: relative;
}
.eventDimg img {
  width: 100%;
  height: 590px;
}

.eventD {
  padding: 15px 30px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.eventLogoD img {
  height: 70px;
  margin-left: 30px;
}
.eventmainTextD {
  margin-top: -76px;
}
.eventmainTextD h2 {
  font-family: "Michroma", sans-serif;
  color: #ffc300;
  font-size: 20px;
  line-height: 45px;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 0;
  text-align: center;
}
/* .eventdatetimeD {
  margin-top: ;
} */
.eventdatetimeD h4 {
  font-size: 14px;
  color: #ffc300;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.eventdatetimeD h4 span {
  border: 1px solid #ffc300;
  padding: 4px 20px;
}
.eventwebD {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
}
.eventwebD p {
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.social_icon a {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #ffd507;
  color: #000;
  text-align: center;
  border-radius: 3px;
  margin: 6px 2px;
}
.social_icon a i {
  font-size: 13px;
  line-height: 24px;
}

.eventTableD {
  margin-top: 25px;
}
.eventTableD table {
  border-color: #fff;
}
.eventTableD .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: none;
  border-color: #000;
  background: #ffc300;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.eventTableD .table > tbody > tr > td {
  padding: 6px;
  vertical-align: middle;
  border-top: 1px solid #ddd;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  text-align: center;
}

.middleSec .panel {
  margin-top: 20px;
}
.middleSec .panel a {
  text-decoration: none;
}
.middleSec .panel-heading {
  font-size: 24px;
}

.middleSec .panel-heading small {
  font-size: 13px;
}
.middleSec .panel-heading span {
  line-height: 20px;
  text-align: right;
  padding: 3px 0;
}

.showPageSec {
  box-shadow: 0px 0px 6px -2px #333;
  margin-top: 30px;
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #313949;
  color: white;
  border: 1px solid #313949;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.dataTable {
  height: 420px;
  overflow-y: scroll;
}

.dataTable .table img {
  height: 40px;
  width: 60px;
}
.dataTable .table tr i {
  font-size: 15px;
  color: #333;
}
.editI {
  opacity: 0;
}
.dataTable .table > thead > tr > th,
.dataTable .table > tbody > tr > th,
.dataTable .table > tfoot > tr > th,
.dataTable .table > thead > tr > td,
.dataTable .table > tbody > tr > td,
.dataTable .table > tfoot > tr > td {
  white-space: nowrap;
  padding: 8px 12px;
  vertical-align: middle;
  border-top: none;
}
.dataTable .table > tbody > tr:hover .editI {
  opacity: 1;
}
.trBack {
  background: #f9f9f9;
}
.marright10 {
  margin-right: 10px;
}

.addPageSec {
  box-shadow: 0px 0px 6px -2px #333;
  margin-top: 30px;
  height: 500px;
  overflow-y: scroll;
  padding: 20px 0;
}
.addPageSec label {
  font-weight: normal;
}
.addPageSec .form-control {
  box-shadow: none;
}

.loginBack {
  background: url(./../Images/dust_scratches.png) center center repeat;
  overflow: hidden;
}
.loginSecD {
  padding: 40px 30px;
  background: rgba(255, 255, 255, 0.4);
  margin: 25% 0;
}
.loginSecD img {
  height: 50px;
  margin: 0 auto;
}
#forgetPassD {
  display: none;
}
.loginSecDsub h4 {
  font-size: 20px;
  color: #337ab7;
  text-align: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
}
.loginSecDsub h4:before {
  display: block;
  content: "";
  height: 2px;
  position: absolute;
  top: 11px;
  width: 35%;
  background: #ccc;
}
.loginSecDsub h4:after {
  display: block;
  content: "";
  height: 2px;
  position: absolute;
  top: 11px;
  width: 35%;
  right: 0;
  background: #ccc;
}

.frgtSecD h4:before {
  width: 22%;
}
.frgtSecD h4:after {
  width: 22%;
}
.loginSecDsub .form-control {
  border-radius: 0 20px 20px 0;
  height: 37px;
  margin-left: -15px;
  padding-left: 20px;
}
.loginSecDsub .btn-primary {
  border-radius: 20px;
  padding: 6px 25px;
  margin: 10px auto;
  display: table;
}
.loginSecDsub .input-group-addon {
  border-radius: 30px;
  position: relative;
  padding: 10px 12px;
  background-color: #868686;
  border: none;
  color: #fff;
  z-index: 90;
}
.btn_forget,
.btn_forget:hover {
  background: none;
  border: none;
  color: #337ab7;
  font-weight: bold;
  font-size: 13px;
}

/*************************************** genarel style close ********************************************/

@media screen and (max-width: 768px) {
}

/* Let's get this party started */
.catby::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.catby::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* Handle */

.catby::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #3e454d;
}

/* Let's get this party started */
.catbyTr::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
.catbyTr::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* Handle */

.catbyTr::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: #ccc;
}

/* Let's get this party started */
.catbyMdl::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.catbyMdl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* Handle */

.catbyMdl::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #3e454d;
}
.pull-right {
  float: right;
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}
.fourzero{
  width: 600px;
  margin: 8% auto;
  padding: 10% 0;
  border: 1px solid #ccc;
  border-radius: 30px;
  font-size: 110px;
  font-weight: 700;
  color: #f33984;
  text-align: center;
  line-height: 50px;
}
.fourzero span{
  font-size: 18px;
  color: #000;
}
.popup .form-control{
  padding: 8px;
  border-radius: 6px;
}
.popup .btn{
  border-radius: 6px;
  margin-top: 20px;
}
/* Background Overlay */
.profile-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Modal Content Styling */
.profile-modal-content {
  background-color: #fff;
  padding: 30px; /* Increased padding for a more comfortable look */
  border-radius: 15px; /* Rounded corners for a softer appearance */
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4); /* Slightly increased shadow */
  position: relative;
  text-align: left;
  max-width: 450px; /* Increased max-width for better readability */
  width: 100%;
}

/* Heading Styling */
.profile-modal-content h2 {
  color: #333;
  font-size: 24px; /* Increased font size for emphasis */
  margin-bottom: 20px; /* Improved spacing */
  text-align: center;
}

/* Label Styling */
.profile-modal-content label {
  display: block;
  margin: 15px 0 8px; /* Adjusted margin for better spacing */
  font-weight: bold;
  color: #555; /* Slightly muted label color for contrast */
}

/* Input Styling */
.profile-modal-content input {
  width: calc(100% - 16px); /* Improved input width calculation */
  padding: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #aaa; /* Subtle border for better visibility */
  border-radius: 5px;
}

/* Button Container Styling */
.profile-modal-content .button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px; /* Increased top margin for better separation */
}

/* Button Styling */
.profile-modal-content button {
  background-color: #090909; /* Green color for positive actions */
  color: #fff;
  padding: 12px 25px; /* Adjusted padding for better button size */
  border: none;
  border-radius: 8px; /* Rounded corners for a friendly look */
  cursor: pointer;
  font-size: 16px; /* Font size adjustment for better visibility */
}

/* Button Hover Styling */
.profile-modal-content button:hover {
  background-color: #212529; /* Darker green on hover for feedback */
}

/* Close Button Styling */
.profile-modal-content .close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  color: #777; /* Slightly muted color for the close button */
  cursor: pointer;
}
/* Add this CSS to your existing styles or create a new CSS file */

.popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 350px;
  text-align: center;
}

.popup-content h2 {
  color: #333;
}

.popup-content p {
  color: #666;
}

button {
  /* background-color: #d9534f;
  color: #333; */
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}

button:hover {
  background-color: #957777;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}
.calender {
  background-color: #fff;
}

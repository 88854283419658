.carousel-indicators li {
  cursor: pointer;
}
/* .adSlider {
  height: 220px;
} */
.navbar-toggle-custom {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggle-custom:hover {
  background-color: transparent;
}
.nav-item a {
  cursor: pointer;
}
